import React from 'react';
import avatar from './bikeavatar_edit.jpg';
import './App.css';

function App() {
  return (
    <div className="container min-w-full bg-gradient-to-br from-orange-500">
        <div className="mx-auto min-h-screen md:w-3/5 space-y-4">
            <span className="leading-relaxed bg-white font-bangers text-black text-7xl px-2 ...">
                Thank you!
            </span>
            <div className="w-4/5 mx-auto grid grid-flow-row grid-cols-1">
                <figure className="bg-slate-100 rounded-md p-8">
                    <img 
                    className="w-24 h-24 md:w-48 md:h-48 rounded-full mx-auto" 
                    alt="Mickey Stringer headshot"
                    src={avatar} 
                    />
                    <div className="pt-4 text-left text-xl font-sans">
                        <p>
                            Thanks for touring with me! I hope you learned some new things about Los Angeles, and had a blast along the way!
                        </p>
                        < br/>
                        <p>
                            Electronic payment options are listed below, and all gratuities
                            are greatly appreciated!
                        </p>
                        <br />
                        <p>
                            If you had a 5-star experience, please leave a review mentioning my name on <a href="https://g.page/bikesandhikesla?share" target="_blank" rel="noreferrer">Google</a> or <a 
                            href="https://www.tripadvisor.com/Attraction_Review-g32655-d1924873-Reviews-Bikes_And_Hikes_LA-Los_Angeles_California.html"
                            target="_blank" 
                            rel="noreferrer"
                            >
                                 Tripadvisor
                            </a>
                            !
                        </p>
                    </div>
                    <figcaption className="pt-4 font-medium">
                        <div className="text-rose-600">
                            Mickey Stringer
                        </div>
                        <div className="text-slate-00">
                            Bikes and Hikes L.A.
                        </div>
                    </figcaption>
                </figure>
            </div>
            <br/>
            <span className="leading-relaxed bg-white font-bangers text-black text-5xl px-2 ...">
                e-pay options
            </span>
            <div className="w-4/5 mx-auto grid grid-flow-row grid-cols-1 mb-8">
                <figure className="bg-slate-100 rounded-md p-8">
                    <div className="text-left text-xl font-sans spacing-y-4">
                        <ul>
                            <li>
                                Venmo: <a href="https://venmo.com/code?user_id=2032235582586880826" target="_blank" rel="noreferrer">@Mickey-Stringer</a>
                            </li>
                            <br />
                            <li>
                                Cash App: <a href="https://cash.app/$mickstringer" target="_blank" rel="noreferrer">$mickstringer</a>
                            </li>
                            <br />
                            <li>
                                Revolut: <a href="https://revolut.me/mickeystringer" target="_blank" rel="noreferrer">@mickeystringer</a>
                            </li>
                        </ul>
                    </div>
                </figure>
            </div>
            <br />
            <span className="leading-relaxed bg-white font-bangers text-black text-5xl px-2 ...">
                Other things!
            </span>
            <div className="w-4/5 mx-auto grid grid-flow-row grid-cols-1 mb-8">
                <figure className="bg-slate-100 rounded-md p-8">
                    <div className="text-left text-xl font-sans spacing-y-4">
                        <p>
                            I'm also a musician!
                        </p>
                        <p>
                            If you'd like to check out my music, see <a href="https://www.mickeystringer.com" target="_blank" rel="noreferrer">mickeystringer.com</a> for 
                            links to my various platforms. 
                        </p>
                        <br />
                        <p>
                            In general, I make rock music with dashes of blues, funk, comedy, 
                            and whatever else inspires me. Of course, my music might not be your 
                            cup of tea, but if you know someone who <i>would</i> enjoy it, 
                            I'd really appreciate you sharing it with them!
                        </p>
                    </div>
                </figure>
            </div>
            <div className="h-32"></div>
        </div>
    </div>
  );
}

export default App;
